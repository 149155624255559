<template>
  <div class="modal">
    <div class="objects__filter flex align-center">
      <r-select
        class="mr-4 flex-1"
        :items="sortTypes"
        label="Вид"
        v-model="selectedSortType"
      />
      <r-select
        class="mr-4 flex-1"
        :items="sortTimer"
        label="Смена камер"
        v-model="selectedSortTimer"
      />
      <select-checkboxes
        class="mr-4 flex-1"
        :items="sortOpportunities"
        label="Возможности"
        v-model="selectedSortOpportunities"
      />
      <select-checkboxes
        class="mr-4 flex-1"
        :items="sortGroups"
        label="Группы"
        v-model="selectedSortGroups"
      >
        <template>
          <router-link
            class="flex align-center sulguni color-rocky"
            to="controller-add"
            style="opacity: 0.72 !important"
          >
            <r-icon
              class="mr-2"
              icon="settings"
              fill="rocky"
            />
            Настроить группы
          </router-link>
        </template>
      </select-checkboxes>
      <r-button
        class="ml-2"
        icon="exit-fullscreen"
        color="secondary"
        icon-size="20"
        @click.native="$attrs.closeModal()"
      />
    </div>
    <div
      class="video_grid"
      :style="`grid-template-columns: repeat(${selectedSortType},1fr);`"
    >
      <div
        class="video_grid__item"
        :key="item + index"
        v-for="(item, index) of videos"
      >
        <video
          v-if="true"
          ref="video"
          style="aspect-ratio: 16/10"
          width="482"
          controls
        />
        <div
          class="error"
          v-else
        >
          <p class="opacity-72 mb-1">
            Видео с камеры «Парк Горького, южная аллея» недоступно
            {{ item }}
          </p>
          <p class="mozzarella opacity-48">
            ул. Академика Королёва, 5
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCheckboxes from './SelectCheckboxes';

export default {
  name: 'ModalVideoWall',
  components: {
    SelectCheckboxes
  },
  data() {
    return {
      sortTypes: [
        {
          id: 2,
          title: '2х2'
        },
        {
          id: 3,
          title: '3х3'
        },
        {
          id: 4,
          title: '4х4'
        },
        {
          id: 5,
          title: '5х5'
        }
      ],
      selectedSortType: 2,
      sortTimer: [
        {
          id: 0,
          title: '30 секунд'
        },
        {
          id: 1,
          title: '1 минута'
        },
        {
          id: 2,
          title: '2 минуты'
        },
        {
          id: 3,
          title: '3 минуты'
        }
      ],
      selectedSortTimer: 1,
      sortOpportunities: [
        {
          id: 0,
          title: 'Распознавание лиц'
        },
        {
          id: 1,
          title: 'Распознавание автономеров'
        },
        {
          id: 2,
          title: 'Распознавание дыма и огня'
        },
        {
          id: 3,
          title: 'Крупногабаритный транспорт'
        },
        {
          id: 4,
          title: 'Выдача видео потока'
        }
      ],
      selectedSortOpportunities: null,
      sortGroups: [
        {
          id: 0,
          title: 'Пешеходные переходы'
        },
        {
          id: 1,
          title: 'Подъезды'
        },
        {
          id: 2,
          title: 'Скверы'
        },
        {
          id: 3,
          title: 'Тротуары'
        },
        {
          id: 4,
          title: 'Парки и скверы'
        }
      ],
      selectedSortGroups: null
    };
  },
  computed: {
    videos() {
      return [
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2',
        '2'
      ].slice(0, this.selectedSortType * this.selectedSortType);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  width: 100%;
  max-width: 1360px;
  padding: 16px;
  height: 100vh;
}

.video_grid {
  margin-top: 16px;
  display: grid;
  grid-gap: 4px;

  &__item {
    background: #e4edfb;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

</style>
