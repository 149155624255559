<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div class="parent align-center">
          <div class="container-upload">
            <r-icon icon="warning" fill="fargo" size="56"/>
            <h1 class="ricotta mb-4 mt-7">{{ title || 'Удалить объект?' }}</h1>
            <div class="mb-8 font">
              {{ subtitle || 'Это действие невозможно будет отменить' }}
            </div>
            <div class="mt-2 buttons">
              <r-button class="flex-1" color="secondary" @click.native="$attrs.closeModal()">
                {{ buttons[0] || 'Не удалять' }}
              </r-button>
              <r-button class="flex-1" @click.native.prevent.stop="Delete">
                {{ buttons[1] || 'Да, удалить' }}
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    id: [Number, String],
    actionPostfix: String,
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => (['Не удалять', 'Да, удалить'])
    },
  },
  data() {
    return {
      upload: false,
    };
  },
  methods: {
    async Delete() {
      const api = new Api();
      /*  await api[`delete${this.$props.actionPostfix}`](this.$props.id).then(() => {
        let r = this.$router.resolve({
          name: 'index',
          query: {
            tab: this.whereBack()
          }
        });
        window.location.assign(r.href)
      })*/
    },
    whereBack() {
      let string = '';
      if (this.$props.actionPostfix === 'Work' || this.$props.actionPostfix === 'Excavation') {
        string = 'work';
      }
      if (this.$props.actionPostfix === 'Organization') {
        string = 'organizations';
      }
      if (this.$props.actionPostfix === 'Statement') {
        string = 'statements';
      }
      return string;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media (max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
</style>
