<template>
  <div>
    <router-link
      :to="{ path: `cameras-details/${card.id}`, query: { trassir: card.isTrassir}}"
      v-if="!!!card.deletedDate"
    >
      <div class="card">
        <div class="card__image">
          <img
            :src="card.photo"
            :alt="card.description"
            v-if="!!card.photo"
          >
          <r-icon
            v-else
            icon="video-control"
            fill="alien"
            size="32"
          />
        </div>
        <div class="card__info">
          <p class="sulguni mb-1 overflow">
            {{ card.name || card.description }}
          </p>
          <p class="mozzarella opacity-72 mb-3 overflow">
            {{ card.address || '—' }}
          </p>
          <!-- <div class="flex align-center mb-3">
            <r-icon
              v-for="opportunity of card.opportunities"
              :key="opportunity.icon"
              class="mr-4 opacity-48"
              :icon="opportunity.icon"
              :title="opportunity.value"
              fill="titanic"
            />
          </div>
          <p
            class="mozzarella opacity-72"
            style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap"
          >
            {{ card.groups.join(', ') }}
          </p>-->
        </div>
        <!--<div
          class="flex align-center"
          v-if="card.handmade"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72 mb-6"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky "
                />
                Дублировать
              </li>
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="onDelete"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div> -->
      </div>
    </router-link>
    <div
      class="card deleted_card"
      v-else
    >
      <div class="card__image">
        <img
          :src="card.image"
          :alt="card.title"
          v-if="!!card.image"
        >
        <r-icon
          v-else
          icon="video-control"
          fill="fargo"
          size="32"
        />
      </div>
      <div class="card__info">
        <p class="sulguni mb-1">
          {{ card.title }}
        </p>
        <p class="mozzarella opacity-72 mb-3">
          {{ card.address }}
        </p>
        <div class="flex align-center mb-3">
          <r-icon
            v-for="icon of card.opportunities"
            :key="icon"
            class="mr-4 opacity-48"
            :icon="icon"
            fill="titanic"
          />
        </div>
        <div
          class="flex align-center"
          style="z-index: 2"
        >
          <r-icon
            class="mr-2"
            icon="block"
            fill="fargo"
          />
          <p class="briscola color-fargo">
            Будет удалено {{ card.deletedDate }}
          </p>
        </div>
      </div>
      <div
        class="flex align-center"
        style="z-index: 2"
      >
        <r-icon
          class="pointer"
          icon="restore"
          @click.native="onRestore"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from '../AdditionalMenu.vue';
import Api from '../../api/Api';
import DeleteModal from '../DeleteModal';

export default {
  name: 'ControllerCard',
  components: { AdditionalMenu },
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },

  computed: {},
  methods: {
    async onDelete() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$props.id,
        actionPostfix: 'Excavation'
      });
    },
    async onRestore() {
      const api = new Api();
      await api.restoreExcavation(this.id);
    },
    copyItem() {
      this.$router.push({ path: `controller-edit/${this.card.id}`, query: { copy: true } });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: grid;
  grid-template-columns: 72px auto 16px;
  grid-gap: 12px;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;
  overflow: hidden;

  &.deleted_card {
    position: relative;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  &__image {
    background: #e4edfb;
    border-radius: 8px;
    overflow: hidden;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  &__header {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 6px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.disabled {
      .sulguni,
      .mozzarella {
        opacity: 0.32 !important;
      }
    }
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}
.overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
