<template>
  <img
      class="modal-view-image__view"
      :style="{
        aspectRatio: ratio
      }"
      :src="'/cityProperty/rest/'+file" alt="">
</template>

<script>
export default {
  name: 'modal-view-image',
  props: {
    file: {
      type: Object
    },
    ratio: {
      type: String,
      default: '16/10'
    }
  }
}
</script>

<style lang="scss">
.modal-view-image{
  &__view{
    max-width: 100%;
  }
}
</style>
