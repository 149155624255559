<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
        after-icon="search"
      />
    </div>
    <yandex-map
      v-if="isShow && isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @dblclick="onMapDblClick"
      :map-type="mapType"
      :options="mapOptions"
      :behaviors="['drag', 'scrollZoom', 'multiTouch']"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
    >
      <slot />
    </yandex-map>

    <div
      v-show="control"
      class="r-map__controls"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        type="secondary"
        size="larishae"
        icon-size="20"
        @click="onCollapseMap"
        title=""
      />
      <drop-down-map-button
        v-if="showType"
        class="mb-4"
        :items="mapViewTypes"
        @select="onChangeView"
      >
        <r-button-simple
          type="secondary"
          size="larishae"
          icon="layers"
          fill="rocky"
          icon-size="20"
          title=""
        />
      </drop-down-map-button>
      <r-button-simple
        icon="add"
        class="amelie"
        fill="amelie"
        type="secondary"
        size="larishae"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        type="secondary"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        type="secondary"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>
import BalloonCard from './BalloonCard.vue';
import DropDownMapButton from './DropDownMapButton.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'RirMap',
  components: {
    BalloonCard,
    DropDownMapButton,
    loadYmap
  },
  props: {
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Array,
      default: () => []
    },
    showType: {
      type: Boolean,
      default: false
    },
    showTraffic: {
      type: Boolean,
      default: false
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },

    dblclickTimeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mapViewTypes: [
        { id: 'map', active: true, value: 'Схема' },
        { id: 'satellite', active: false, value: 'Спутник' },
        { id: 'hybrid', active: false, value: 'Гибрид' }
      ],
      mapType: 'map',
      isShow: true,
      mapControls: this.controls, // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },

      clusterOptions: {
        // Название свойства = название кластера (к которому будет применен данный стиль)
        main: {
          //   clusterDisableClickZoom: true,
        //  clusterOpenBalloonOnClick: false,
          /* groupByCoordinates: true,
           clusterGroupByCoordinates: true, */
          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ],
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: true,
          clusterHideIconOnBalloonOpen: false,
          clusterBalloonLayout: [
            '<ul class=balloon_list pa-4>',
            '{% for geoObject in properties.geoObjects %}',
            '<li><a href="/admin/video-control/cameras-details/{{ geoObject.properties.balloonContentHeader.id|raw }}" class="balloon_list_item sulguni">{{ geoObject.properties.balloonContentHeader.name|raw }}</a></li>',
            '{% endfor %}',
            '</ul>'
          ].join('')

          //   Шрифт кластера
          //   clusterIconContentLayout:
          //     '<p style="color: #FFFFFF; font-weight: bold;font-size: 24px; position: absolute;">$[properties.geoObjects.length]</p>'
        },
        orgs: {
          clusterOpenBalloonOnClick: false,
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      isYmapsReady: false
    };
  },

  watch: {
    showTraffic(val) {
      const isTrafficShown = !!this.mapInstanse.controls.get('trafficControl').isTrafficShown();

      // если не включен контрол трафика
      if (!this.mapInstanse.controls.get('trafficControl')) return;

      if (val) {
        if (isTrafficShown) return;
        this.mapInstanse.controls.get('trafficControl').showTraffic();
      } else {
        this.mapInstanse.controls.get('trafficControl').hideTraffic();
      }
    }
  },

  async mounted() {
    // настройки карты
    const settings = {
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },

  methods: {
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onChangeView(obj) {
      // Не хочет менять подложку без выключения
      this.isShow = false;
      this.mapType = obj.id;
      this.mapViewTypes = this.mapViewTypes.map(el => ({ ...el, active: el.id === obj.id }));
      this.$nextTick(() => {
        this.isShow = true;
      });
    },

    onMapInit(e) {
      // console.log('mapInit | objects - ', e.geoObjects.getLength(), e.geoObjects);
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
      // console.log('map', e);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onLocation() {
      // console.log('length', this.mapInstanse.geoObjects.getLength());
      // this.mapInstanse.geoObjects.each(function(collection) {
      //   console.log('collectionEach', collection.each);
      //   const checkMarker = marker => {
      //     const markerId = marker.properties.get('markerId');
      //   };
      //   collection.each(checkMarker);
      // });
      // this.mapInstanse.geoObjects.each(function(geoObject) {
      //   // if (geoObject.properties.get('id') == 'some id') {
      //   // return false;
      //   // }
      // });
      //   console.log(
      //     'OM',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      // this.mapInstanse.geoObjects
      //   .get(0)
      //   .clusters.state.set(
      //     'activeObject',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      //   console.log('iterator', this.mapInstanse.geoObjects.getIterator().getNext());
      //   console.log(
      //     '1269',
      //     this.mapInstanse.geoObjects.get(0).clusters._objectManager.objects._objectsById['1263']
      //   );
      //   // Открыть balloon с id = 1386
      //   this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.balloon.open('1386');
      //   this.mapInstanse.container.fitToViewport();
      //         <BalloonCard
      //     slot="balloon"
      //     :title="marker.title"
      //     :address="marker.address"
      //     :img="marker.img"
      //     :data-id="marker.uid"
      //   >
      //   console.log('objects', this.mapInstance.geoObjects.get(0).get(n).balloon.open());
      //   this.mapInstanse.objects.balloon.open(1);
      //   this.mapInstanse.balloon.open(this.mapInstanse.getCenter(), BalloonCard);
    },
    onMapClick(e) {
      //   if (this.markers.length) return;
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте

      if (this.dblclickTimeout) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
          this.$emit('click', coords);
        }, this.dblclickTimeout);
      } else {
        this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
        this.$emit('click', coords);
      }
    },

    onMapDblClick(e) {
      const coords = e.get('coords');
      //   Закрываем открытый Balloon при клике по карте
      if (this.dblclickTimeout && this.timeout) clearTimeout(this.timeout);
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('dblclick', coords);
    },

    onRightClick(e) {
      this.$emit('onRightClick', e);
    },

    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
            // console.log('width', entry.contentRect.width);
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>

.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
      background-color: #fff;
    }
  }
  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;
    display: grid;
    ::v-deep .RButton__icon{
      margin-right: 0!important;
    }
    .RButton.content{
      align-items: center;
      justify-content: center;
    }
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}

// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: hidden;
 /* transform: translate(66px, -10px);*/
  transform: translate(105px, 0px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
::v-deep .balloon_list{
  padding: 16px;
  display: grid;
  grid-gap: 12px;
  max-height: 215px;
  overflow-y: auto;
  li{
    list-style: none;
  }
  a{
    font-family: 'Golos';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #04153E;
    &:hover{
      opacity: .8;
    }
  }
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}

</style>
