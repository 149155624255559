import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import store from '../store';
import NotFoundPage from '../views/NotFoundPage';
import Cameras from '../views/Cameras';
import CamerasDetails from '../views/CamerasDetails';
import Person from '../views/Person';
import PersonDetails from '../views/PersonDetails';
import FindCarPlate from '../views/FindCarPlate';
import CarPlate from "../views/CarPlate";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,

    children: [
      {
        path: '',
        component: ObjectList
      },
      {
        path: 'cameras-add',
        component: Cameras
      },
      {
        name: 'cameras-details',
        path: 'cameras-details/:id',
        component: CamerasDetails
      },
      {
        path: 'person-add',
        component: Person
      },
      {
        name: 'person-details',
        path: 'person-details/:id',
        component: PersonDetails
      },
      {
        path: 'find-plate',
        component: FindCarPlate
      },
      {
        path: 'car-plate/:id',
        component: CarPlate
      },
      { path: '*', name: 'NotFoundPage', component: NotFoundPage }
    ]
  }

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  console.log('ROUTER_BEFORE_EACH');
  const user = store.getters.userAccesses;
  // if (!!user.access) {
  //   CheckUser(user, next)
  // } else {
  store.dispatch('getUserAccesses').then(() => {
    CheckUser(store.getters.userAccesses, next);
  });
  store.dispatch('getUserInfo');
  // }
});

const CheckUser = (user, next) => {
  if (user.status <= 0) {
    // не залогинен
    window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
  } else {
    // залогинен
    if (!user.access.role === 'ANONYMOUS') {
      // отправить на главную/ обычный юзер
      window.location.href = '/';
    } else {
      next();
    }
  }
};

export default router;
