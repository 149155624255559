import ApiService from './ApiService';

let proxy = '';
if (process.env.NODE_ENV === 'development') {
  proxy = '/proxy';
}
export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post(`${proxy}/mob_ajax.php?action=geosearch`, payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/CCTV');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // cameras
  async getCameras(camType) {
    console.log('getCameras', camType, camType === 'trassir');
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=getCameras`, {
      currentMainMenuNumber: 2,
      camerasOption_1: String(camType) === 'client', // true - показывать клиентские
      camerasOption_2: String(camType) === 'trassir', // true - показывать трассир
      camerasOption_3: false,
      camerasOption_4: false,
      zakazVideoOption_2: false,
      camerasOptionSub_1: true,
      camerasOptionSub_2: false,
      camerasOptionSub_3: false,
      camerasOptionSub_4: false,
      camerasOptionSub_5: true,
      isRostelekomExists: false,
      editing: false
    });
    return data;
  }

  async getCamerasById(id, isTrassir = 0) {
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=getCameras`, {
      id,
      camerasOption_1: Number(!isTrassir),
      camerasOption_2: isTrassir,
      camerasOptionSub_5: true
    });
    return data.all[0];
  }

  async operateCamera(payload) {
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=operateCamera`, payload);
    return data;
  }

  // stream

  async getTrassirStream({ id, start }) {
    const { data } = await this._axios.get(`${proxy}/trassir/rest/stream/${id}/master.m3u8`, {
      params: {
        start
      }
    });
    return data;
  }

  async wantedVideo(payload) {
    const { data } = await this._axios.post(`${proxy}/trassirProxy.php?action=wantedVideo`, payload);
    return data;
  }

  async uploadImage(payload) {
    const { data } = await this._axios.post('/uploadfile/index.php', payload);
    return data;
  }

  async sendToTrassir(payload) {
    const { data } = await this._axios.post(`${proxy}/trassirProxy.php?action=sendToTrassir`, payload);
    return data;
  }

  async zakazVideo(payload) {
    const { data } = await this._axios.post(`${proxy}/ajax.php?action=zakazVideo`, payload);
    return data;
  }

  async downloadVideo(orderId) {
    const { data } = await this._axios.get(`${proxy}/ajax.php?action=zakazVideo&&orderId=${orderId}`);
    return data;
  }

  async getSensorCam(url, params) {
    return await this._axios.get(url, {
      params
    });
  }

  async getScreenshotSensorCam(url, params) {
    return await this._axios.get(url, {
      params
    });
  }

  async getTrafficChart(payload) {
    //
    const { data } = await this._axios.post(`${proxy}/trassirProxy.php?action=getLayer33chart`,
      {
        action: 'chart',
        sourceName: 'layer33',
        meterChartCHeckbox: 0,
        interval: 'DAY',
        ...payload
      });
    return data;
  }

  async getCamerasLog() {
    const { data } = await this._axios.post('/ajax.php?action=getCamerasLog');
    return data;
  }
}
