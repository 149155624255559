import './set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import rir from '@/plugins/RirLib';
import App from './App.vue';
import router from './router';
import store from './store';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

Vue.use(Vuelidate);
Vue.use(VueMask);
const mapSettings = {
  // apiKey: '',
  lang: 'ru_RU',
  // coordorder: 'latlong',
  coordorder: 'longlat',
  version: '2.1'
};

Vue.prototype.$storage = window?.__CONFIG__?.storage || null;
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng]//.reverse()
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;

Vue.prototype.$S3_COMMON = S3_COMMON;
const mapMarkers = {
  mapPin: "data:image/svg+xml;charset=UTF-8,%3csvg width='56' height='68' viewBox='0 0 56 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_d_1545_226726)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 24C40 17.3726 34.6274 12 28 12C21.3726 12 16 17.3726 16 24C16 30.6274 21.3726 36 28 36C28.9385 36 28.1059 39.0311 27.2721 42.0663C26.4391 45.0986 25.5 48 26.5352 48C28.7597 48 40 34.8279 40 24Z' fill='%233D75E4'/%3e%3ccircle cx='28' cy='24' r='6' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_d_1545_226726' x='0' y='0' width='56' height='68' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='4'/%3e%3cfeGaussianBlur stdDeviation='8'/%3e%3cfeColorMatrix type='matrix' values='0 0 0 0 0.0156863 0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0.16 0'/%3e%3cfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1545_226726'/%3e%3cfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1545_226726' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e",
  mapPinRed: "data:image/svg+xml;charset=UTF-8,%3csvg width='56' height='68' viewBox='0 0 56 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg filter='url(%23filter0_d_653_499700)'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M40 24C40 17.3726 34.6274 12 28 12C21.3726 12 16 17.3726 16 24C16 30.6274 21.3726 36 28 36C28.9385 36 28.1059 39.0311 27.2721 42.0663C26.4391 45.0986 25.5 48 26.5352 48C28.7597 48 40 34.8279 40 24Z' fill='%23E14761'/%3e%3ccircle cx='28' cy='24' r='6' fill='white'/%3e%3c/g%3e%3cdefs%3e%3cfilter id='filter0_d_653_499700' x='0' y='0' width='56' height='68' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3e%3cfeFlood flood-opacity='0' result='BackgroundImageFix'/%3e%3cfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3e%3cfeOffset dy='4'/%3e%3cfeGaussianBlur stdDeviation='8'/%3e%3cfeColorMatrix type='matrix' values='0 0 0 0 0.0156863 0 0 0 0 0.0823529 0 0 0 0 0.243137 0 0 0 0.16 0'/%3e%3cfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_653_499700'/%3e%3cfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_653_499700' result='shape'/%3e%3c/filter%3e%3c/defs%3e%3c/svg%3e "
};
Vue.prototype.$markerIcon = (iconName = 'marker') => ({
  layout: 'default#image',
  imageSize: [56, 68],
  imageHref: mapMarkers[iconName] // `${S3_COMMON}/icons/map/${iconName}.svg`
});
Vue.use(YmapPlugin, mapSettings);

// Vue.use(RirLib);
Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {}
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
