<template>
  <div
    class="video_container"
    :class="{ fullscreen: fullscreen, video_pause: pause }"
  >
    <video
      autoplay
      muted
      ref="player"
      @timeupdate="videoTimeUpdate"
      @click="playPause"
      @pause="pause = true"
      @play="pause = false"
      @loadstart="loading = true"
      @canplay="loading = false"
    >
      <source
        :src="video"
        type="video/mp4"
      >
    </video>
    <loader v-if="loading" />
    <div class="controls">
      <p
        class="sulguni text-amelie"
        v-text="fullscreen ? title : ''"
      />
      <div class="flex align-center">
        <r-icon
          class="mr-5 opacity-72 pointer"
          icon="photo"
          fill="amelie"
        />
        <r-icon
          class="opacity-72 pointer"
          :icon="fullscreen ? 'exit-fullscreen' : 'fullscreen'"
          fill="amelie"
          @click.native="fullscreen = !fullscreen"
        />
      </div>
    </div>
    <div
      class="play-pause__button"
      @click="playPause"
    >
      <r-icon
        icon="play-solid"
        size="32"
        fill="rocky"
        v-if="pause"
      />
      <r-icon
        icon="pause-solid"
        size="32"
        fill="rocky"
        v-else
      />
    </div>
    <div
      class="progress-bar__preview"
      v-if="onMouseMove"
      :style="`left:${previewPosition}px`"
      ref="preview"
    >
      <div class="time briscola">
        {{ previewTime }}
      </div>
    </div>
    <progress
      min="0"
      max="100"
      :value="videoProgress"
      @click="progressBarClick"
      @mousemove="progressBarHover"
      @mouseout="onMouseMove = false"
      @mouseover="onMouseMove = true"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import Hls from 'hls.js';
import Loader from './Loader';

export default {
  name: 'VideoStream',
  components: {
    Loader
  },

  props: {
    video: String,
    title: String,
    stopVideo: Boolean
  },
  data() {
    return {
      fullscreen: false,
      videoProgress: 0,
      onMouseMove: false,
      previewTime: '0',
      previewPosition: 0,
      pause: false,
      loading: true
    };
  },
  async mounted() {
    const hls = new Hls();
    const stream = this.$props.video;
    const { player } = this.$refs;
    hls.loadSource(stream);
    hls.attachMedia(player);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      player.play();
    });
    hls.on('hlsError', (e, api, data) => {
      console.log(data, e);
    });
  },
  methods: {
    progressBarHover(e) {
      const { player } = this.$refs;
      const previewSidePosition = this.$refs.preview.offsetWidth / 2 + 8;
      const percent = e.offsetX / e.target.offsetWidth;
      const time = percent * player.duration;

      this.previewPosition = Math.max(
        Math.min(e.target.offsetWidth - previewSidePosition, e.offsetX),
        previewSidePosition
      );

      this.previewTime = this.formatTime(time); // 18:00:16
    },
    playPause() {
      const { player } = this.$refs;
      if (this.pause) {
        player.play();
      } else {
        player.pause();
      }
    },
    /* progressBarHover: _.debounce(function (e) {
       const player = this.$refs.player;
       let percent = e.offsetX / e.target.offsetWidth;
       let time = percent * player.duration;
       this.previewPosition = e.offsetX;
       console.log(e.offsetX)
       this.previewTime = time //18:00:16
     }, 100), */
    progressBarClick(e) {
      const { player } = this.$refs;
      const percent = e.offsetX / e.target.offsetWidth;

      player.currentTime = percent * player.duration;
      this.videoProgress = Math.floor(percent / 100);
    },
    videoTimeUpdate(e) {
      if (!isNaN(e.target.duration)) {
        this.videoProgress = (e.target.currentTime / e.target.duration) * 100;
      }
      if (this.$props.stopVideo) {
        e.target.pause();
      }
    },
    formatTime(seconds) {
      let hours = Math.floor(seconds / 3600);
      let min = Math.floor(seconds / 60);
      let sec = Math.floor(seconds - (min * 60 - hours * 3600));
      if (hours < 10) {
        hours = `0${hours}`;
      }
      if (min < 10) {
        min = `0${min}`;
      }
      if (sec < 10) {
        sec = `0${sec}`;
      }
      return `${hours}:${min}:${sec}`;
    }
  }
};
</script>

<style scoped lang="scss">
$videoWidth: calc((100vh - 324px) * 1.76);
$videoHeight: calc(100vh - 329px);
.play-pause__button {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  margin-top: -44px;
  margin-left: -44px;
  cursor: pointer;
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px -1px 13px rgba(255, 255, 255, 0.33);
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.2s;

  &:hover {
    opacity: 1 !important;
  }
}
::v-deep .loader{
  position: absolute;
}

.progress-bar__preview {
  pointer-events: none;
  user-select: none;
  //background: red;
  position: absolute;
  bottom: 24px;
  transform: translateX(-50%);
  /*width: 25%;
  height: 25%;
  max-width: 336px;
  max-height: 210px;
  min-width: 155px;
  min-height: 87px;*/
  opacity: 0;
  /* transition-delay: .2s;
   transition: .3s;*/

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .time {
    padding: 4px 8px;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(#f6f9fe, 0.72);
    border-radius: 4px;
  }
}

.video_container {
  background: #fef8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $videoWidth;
  height: $videoHeight;
  min-height: 349px;
  min-width: 621px;
  position: relative;
  overflow: hidden;

  progress {
    opacity: 0;
    transition: 0.3s;
    transition-delay: 0.2s;
    position: absolute;
    cursor: pointer;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 16px;
    border: none;
    background: #434343;

    &::-moz-progress-bar {
      color: rgba(#3d75e4, 0.72);
      background: rgba(#ffffff, 0.72);
    }

    &[value]::-webkit-progress-bar {
      background-color: rgba(#ffffff, 0.72);
    }

    &[value]::-webkit-progress-value {
      background-color: rgba(#3d75e4, 0.72);
    }
  }

  &.fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: #04153e;
  }

  video {
    width: 100%;
    height: 100%;
  }

  ::v-deep .loading-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    .loading-ring__text {
      display: none;
    }
  }

  .controls {
    //transform: translateY(-100%);
    opacity: 0;
    transition: 0.3s;
    transition-delay: 0.2s;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.32) 100%);
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    ::v-deep svg {
      path {
        fill: #ffffff;
      }
    }
  }

  &:hover {
    .controls {
      transform: translateY(0);
      opacity: 1;
    }

    progress {
      transform: translateY(0);
      opacity: 1;
    }

    .progress-bar__preview {
      opacity: 1;
    }

    .play-pause__button {
      opacity: 0.72;
    }
  }

  &.video_pause {
    .controls {
      transform: translateY(0);
      opacity: 1;
    }

    .progress-bar__preview {
      opacity: 1;
    }

    progress {
      transform: translateY(0);
      opacity: 1;
    }

    .play-pause__button {
      opacity: 0.72;
    }
  }
}
</style>
