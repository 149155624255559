<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'VideoControlAdminApp',
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  }
};
</script>
<style lang="scss">
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
textarea:focus{
  background: transparent;
}
</style>
