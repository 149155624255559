<template>
  <div>
    <div class="flex align-center">
      <r-select
        class="flex-1"
        :items="sortTypes"
        label="Тип"
        v-model="selectedSortType"
      />
      <r-date-picker
        class="flex-1 ml-6"
        label="Период"
        period
        v-model="date"
      />
    </div>
    <div class="flex justify-end parmigiano color-titanic opacity-48  mt-6 mb-2">
      {{ !!searchText ? 'Найдено' : 'Всего' }} {{ countObjects }}
    </div>
    <vue-table
      :list="list"
      :headings="headings"
      :columns-grid="tableGridColumns"
      class="rows_space-between"
    />
  </div>
</template>

<script>
import VueTable from '../Table';
import Api from '../../api/Api';
import { formattedDate } from '../../helpers/utils';

export default {
  name: 'CamerasDetailsSensorsTab',
  components: { VueTable },
  data() {
    return {
      date: null,
      selectedSortType: 'SMOKE_DETECTED',
      sortTypes: [
        {
          id: 'SMOKE_DETECTED',
          title: 'Дым'
        },
        {
          id: 'OBJECT_SIZE_ALARM',
          title: 'Крупногабаритный транспорт'
        },
        {
          id: 'FIRE_DETECTED',
          title: 'Пожар'
        }
      ],
      events: {
        SMOKE_DETECTED: 'Дым',
        OBJECT_SIZE_ALARM: 'Крупногабаритный транспорт',
        FIRE_DETECTED: 'Пожар'
      },
      searchText: '',
      headings: [
        {
          title: 'Скриншот',
          field: 'screenshot'
        },
        {
          title: 'Тип',
          field: 'type'
        },
        {
          title: 'Дата',
          field: 'date'
        }
      ],
      tableGridColumns: ['103px', '350px', '90px', '170px'],
      list: []
    };
  },
  computed: {
    countObjects() {
      return this.list.length;
    }
  },
  mounted() {
    this.date = [
      new Date().toLocaleString('en-CA', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      new Date().toLocaleString('en-CA', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      })
    ];
    this.getSensor();

  },
  methods: {
    async getSensor() {
      const { data } = await new Api().getSensorCam('/trassir/rest/commonEvent', {
        ch: this.$route.params.id,
        event: this.selectedSortType.id,
        to: this.date[0],
        from: this.date[1]
      });
      this.list = data.map(el => ({
        id: el.screenshot.channel.id,
        columns: {
          screenshot: `/trassir/rest/scr/${el.screenshot.channel.id}/thumb`,
          type: this.events[el.eventType],
          date: {
            title: formattedDate(Number(el.timestamp)),
            subtitle: formattedDate(Number(el.timestamp), {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            })
          },
          videoView: true
        }
      }));
    }
  }
};
</script>

<style scoped lang="scss">
.history {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  grid-gap: 24px;

  &__item {
    * {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      * {
        color: #214eb0;
      }
    }
  }
}
</style>
