<template>
  <div class="av-uploader flex align-center justify-center">
    <r-spinner
      v-show="loading"
    />
    <input
      v-show="false"
      type="file"
      ref="file"
      @change="uploadImage($event)"
      :accept="accept"
    >
    <div
      v-show="!loading"
      v-if="imageUrl"
      class="av-uploader__picture"
    >
      <img
        class="av-uploader__img"
        :src="imageUrl"
        alt=""
      >
      <div
        v-if="canEdit"
        class="av-uploader__img-edit"
      >
        <r-icon
          icon="search"
          class="mb-3"
          fill="amelie"
          @click.native="$emit('onViewImage')"
        />
        <r-popover content-class="av-uploader__settings">
          <template #activator="{ on }">
            <r-icon
              icon="settings"
              fill="amelie"
              @click.native="on.click"
            />
          </template>
          <template #content>
            <div class="flex flex-column py-3">
              <a
                @click="$refs.file.click()"
                class="av-uploader__link flex align-center"
              >
                <r-icon
                  class="mr-3"
                  icon="update"
                  fill="rocky"
                />
                <span>Заменить</span>
              </a>
              <a
                @click="$emit('deleteFile')"
                class="av-uploader__link flex align-center"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                  @click.native="on.click"
                />
                <span>Удалить</span>
              </a>
            </div>
          </template>
        </r-popover>
        <!--        <span class="briscola mt-1">{{ editText }}</span>
         @click.native="$refs.file.click()"
        -->
      </div>
    </div>
    <div
      v-show="!loading"
      class="av-uploader__upload"
      v-else
      @click="$refs.file.click()"
    >
      <r-icon
        v-if="icon"
        :icon="icon"
      />
      <span class="briscola">{{ uploadText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImgUploader',
  props: {
    uploadText: {
      type: String,
      default: 'Загрузить фото'
    },
    editText: {
      type: String,
      default: 'Изменить'
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    icon: {
      type: [String, null],
      default: 'photo'
    },
    src: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/jpg'
    }
  },
  data: () => ({
    imageUrl: null
  }),
  watch: {
    src: {
      immediate: true,
      handler(val) {
        this.imageUrl = val;
      }
    }
  },
  methods: {
    uploadImage(event) {
      /// Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        this.$emit('change', files[0]);
      }
    }
  }
};
</script>

<style lang="scss">
.av-uploader {
  height: 104px;
  width: 104px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(61, 117, 228, 0.08);
  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__picture {
    height: 100%;
    width: 100%;
    position: relative;
  }
  &__settings {
    max-width: 100% !important;
    margin-left: -69px;
    & + * .av-uploader__img-edit {
      opacity: 0.72 !important;
      background-color: rgb(80 80 80 / 30%) !important;
    }
  }
  &__link {
    opacity: 0.72;
    padding: 12px 20px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  &__img-edit {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    color: #fff;
    padding: 16px;
    &:hover {
      cursor: pointer;
      opacity: 0.72;
      background-color: rgb(80 80 80 / 30%);
    }
  }
  &__upload {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    opacity: 0.48;
    transition: 0.2s all;
    &:hover {
      cursor: pointer;
      opacity: 0.72;
    }
  }
}
</style>
